var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('main',{staticClass:"pt-5 px-6"},[_c('trac-back-button',[_vm._v("Inventory Transfers")]),_c('div',{staticClass:"flex justify-between items-center py-4"},[_c('h2',{staticClass:"font-bold capitalize"},[_vm._v(_vm._s(_vm.transfer.name))]),_c('div',{staticClass:"grid grid-cols-2 gap-x-4"},[(!_vm.transfer.incoming)?_c('trac-button',{on:{"button-clicked":_vm.holdTransfer}},[_vm._v("Hold Order")]):_vm._e(),(
            _vm.transfer.incoming &&
              !_vm.transfer.canceled &&
              _vm.transfer.status !== 'Transferred'
          )?_c('trac-button',{on:{"button-clicked":_vm.acceptTransfer}},[_vm._v("Accept Order")]):_vm._e(),_c('trac-button',{on:{"button-clicked":_vm.$router.back}},[_vm._v("Close")])],1)]),_c('div',{staticClass:"flex justify-between my-10 shadow-sm py-5 px-24"},[_c('div',{staticClass:"grid grid-cols-2 gap-x-6 gap-y-2"},[_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Source store:")]),_c('span',{staticClass:"text-xs capitalize"},[_vm._v(_vm._s(_vm.transfer.source.store_name))]),_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Destination store:")]),_c('span',{staticClass:"text-xs capitalize"},[_vm._v(_vm._s(_vm.transfer.destination.store_name))]),_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Ordered by:")]),_c('span',{staticClass:"text-xs capitalize"},[_vm._v(_vm._s(_vm.transfer.ordered_by))])]),_c('div',{staticClass:"grid grid-cols-2 gap-x-6 gap-y-2"},[_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Transfer status:")]),_c('span',{staticClass:"text-xs capitalize",class:_vm.transfer.status === 'Transferred'
              ? 'text-secondaryGreen'
              : _vm.transfer.status === 'Held'
              ? 'text-primaryBlue'
              : _vm.transfer.status === 'In Transit'
              ? 'text-yellow-500'
              : 'text-secondaryRed'},[_vm._v(_vm._s(_vm.transfer.status))]),_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Transfer date:")]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.moment(_vm.transfer.transfer_date).format("DD/MM/YYYY")))]),_c('p',{staticClass:"text-xs font-bold"},[_vm._v("Received date:")]),(_vm.transfer.received_date)?_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.moment(_vm.transfer.received_date).format("DD/MM/YYYY")))]):_c('span',{staticClass:"text-xs"},[_vm._v("N/A")])])]),_c('div',{staticClass:"w-full my-20 px-4"},[_c('h3',{staticClass:"font-bold my-2"},[_vm._v("Items")]),_c('table',{staticClass:"w-full"},[_vm._m(0),_c('tbody',_vm._l((_vm.transfer.items),function(item,i){return _c('tr',{key:i,class:i % 2 === 0 ? '' : 'bg-gray-100'},[_c('td',{staticClass:"p-2 text-xs capitalize"},[_vm._v(_vm._s(item.title))]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(_vm._s(item.barcode))]),_c('td',{staticClass:"p-2 text-xs capitalize"},[_vm._v(_vm._s(item.category))]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(_vm._s(item.sent_quantity))]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.price)))])])}),0)])]),_c('div',{staticClass:"flex justify-end"},[(!_vm.transfer.incoming)?_c('trac-button',{on:{"button-clicked":_vm.cancelTransfer}},[_vm._v("Cancel")]):_vm._e()],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"bg-blue-100"},[_c('tr',[_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Item")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("SKU")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Category")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Units Received")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Price")])])])}]

export { render, staticRenderFns }