<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="pt-5 px-6">
      <trac-back-button>Inventory Transfers</trac-back-button>
      <div class="flex justify-between items-center py-4">
        <h2 class="font-bold capitalize">{{ transfer.name }}</h2>
        <div class="grid grid-cols-2 gap-x-4">
          <trac-button v-if="!transfer.incoming" @button-clicked="holdTransfer"
            >Hold Order</trac-button
          >
          <trac-button
            @button-clicked="acceptTransfer"
            v-if="
              transfer.incoming &&
                !transfer.canceled &&
                transfer.status !== 'Transferred'
            "
            >Accept Order</trac-button
          >
          <trac-button @button-clicked="$router.back">Close</trac-button>
        </div>
      </div>
      <div class="flex justify-between my-10 shadow-sm py-5 px-24">
        <div class="grid grid-cols-2 gap-x-6 gap-y-2">
          <p class="text-xs font-bold">Source store:</p>
          <span class="text-xs capitalize">{{
            transfer.source.store_name
          }}</span>
          <p class="text-xs font-bold">Destination store:</p>
          <span class="text-xs capitalize">{{
            transfer.destination.store_name
          }}</span>
          <p class="text-xs font-bold">Ordered by:</p>
          <span class="text-xs capitalize">{{ transfer.ordered_by }}</span>
        </div>
        <div class="grid grid-cols-2 gap-x-6 gap-y-2">
          <p class="text-xs font-bold">Transfer status:</p>
          <span
            class="text-xs capitalize"
            :class="
              transfer.status === 'Transferred'
                ? 'text-secondaryGreen'
                : transfer.status === 'Held'
                ? 'text-primaryBlue'
                : transfer.status === 'In Transit'
                ? 'text-yellow-500'
                : 'text-secondaryRed'
            "
            >{{ transfer.status }}</span
          >
          <p class="text-xs font-bold">Transfer date:</p>
          <span class="text-xs">{{
            moment(transfer.transfer_date).format("DD/MM/YYYY")
          }}</span>
          <p class="text-xs font-bold">Received date:</p>
          <span class="text-xs" v-if="transfer.received_date">{{
            moment(transfer.received_date).format("DD/MM/YYYY")
          }}</span>
          <span class="text-xs" v-else>N/A</span>
        </div>
      </div>
      <div class="w-full my-20 px-4">
        <h3 class="font-bold my-2">Items</h3>
        <table class="w-full">
          <thead class="bg-blue-100">
            <tr>
              <th class="text-left p-2 text-xs">Item</th>
              <th class="text-left p-2 text-xs">SKU</th>
              <th class="text-left p-2 text-xs">Category</th>
              <th class="text-left p-2 text-xs">Units Received</th>
              <th class="text-left p-2 text-xs">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="i % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(item, i) in transfer.items"
              :key="i"
            >
              <td class="p-2 text-xs capitalize">{{ item.title }}</td>
              <td class="p-2 text-xs">{{ item.barcode }}</td>
              <td class="p-2 text-xs capitalize">{{ item.category }}</td>
              <td class="p-2 text-xs">{{ item.sent_quantity }}</td>
              <td class="p-2 text-xs">{{ item.price | formatPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end">
        <trac-button @button-clicked="cancelTransfer" v-if="!transfer.incoming"
          >Cancel</trac-button
        >
      </div>
    </main>
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";
import moment from "moment";
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      transfer: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.fetchTransfers();
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  methods: {
    fetchTransfers() {
      this.transfer = GET_LOCAL_DB_DATA("transfer-details");
    },
    async holdTransfer() {
      this.isLoading = true;
      const payload = {
        _id: this.transfer._id,
      };

      await this.$store.dispatch("HOLD_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    async cancelTransfer() {
      this.isLoading = true;
      const payload = {
        _id: this.transfer._id,
      };

      await this.$store.dispatch("CANCEL_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    async acceptTransfer() {
      this.isLoading = true;
      const payload = {
        _id: this.transfer._id,
        body: {
          destination: {
            store_id: this.transfer.destination.store_name,
            store_name: this.transfer.destination.store_id,
          },
          items: this.transfer.items.map((item) => {
            return {
              name: item.title,
              id: item._id,
              variant: item.variation,
              sent_quantity: item.sent_quantity,
              received_quantity: item.received_quantity,
            };
          }),
        },
      };

      await this.$store.dispatch("ACCEPT_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        eventBus.$emit('trac-alert', { message: res.message });
        // alert(res.message);
      }
      this.isLoading = false;
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        const products = res.items || [];
        this.transfer.items = this.transfer.items
          .map((item) => {
            return {
              ...item,
              ...products.find((product) => product._id === item.id),
            };
          })
          .filter((prod) => prod._id);
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit('trac-alert', { message: res.message || "Error: Error with network." });
      }
    },
  },
};
</script>

<style></style>
